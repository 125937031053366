import {
  every,
  filter,
  flattenDeep,
  includes,
  map,
  mapValues,
  uniq,
} from 'lodash';
import { formatNoDecimals, roundDecimals } from './formatNumbers';

export const roundTotals = (values, isVisible) => mapValues(values, (value, key) => {
  if (key === 'numOfRounds' || key === 'numOfWonRounds' || key === 'numOfPlayers' || key === 'numOfFreeRounds') {
    return formatNoDecimals(value);
  }
  if (value === '') {
    return '';
  }
  return isVisible ? roundDecimals(value) : '';
});

export const filterTotals = (filteredCompanies, totals, companies, currency, isUnifiedCurrency) => {
  if (isUnifiedCurrency) {
    return roundTotals(totals, true);
  }
  if (filteredCompanies && filteredCompanies.length) {
    if (!currency) {
      const picked = filter(companies,
        (company) => includes(filteredCompanies, company.id));
      return roundTotals(totals, uniq(flattenDeep(map(picked, 'currencies'))).length < 2);
    }
    return roundTotals(totals, true);
  }
  if (!currency) {
    return roundTotals(totals,
      every(companies, ['currencies[0]', companies[0].currencies[0]]));
  }
  return roundTotals(totals, true);
};

export const decorateTotals = (totals, total) => {
  const totalsWithPercentage = ['rtpWithPromotion', 'srp'];
  if (totals[total.key]) {
    return includes(totalsWithPercentage, total.key) ? `${totals[total.key]}%` : totals[total.key];
  }
  return '';
};

export const getTotalKeys = (visibleHeaders) => {
  const totalKeys = [];
  visibleHeaders.forEach((header) => {
    totalKeys.push({
      text: header.text,
      key: header.value,
    });
  });
  return totalKeys;
};
